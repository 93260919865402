.input-container {
  .input-title {
    h1 {
      font-weight: 300 !important;
      display: flex;
      gap: 5px;
      span {
        gap: 2px;
        display: flex;
        justify-content: center;
        align-items: baseline;
        font-size: 16px;
        img {
          filter: invert(1);
          margin: 0px 4px 2px 1px;
        }
      }
    }
    div {
      p {
        margin: 0px;
        max-width: 100%;
        width: inherit;
        font-weight: unset;
        font-size: 20px;
        line-height: 28px;
        color: var(--color);
        text-align: left;
        filter: opacity(0.7);
        padding-left: 5%;
      }
    }
  }
  .input {
    .category {
      margin-top: 35px;
    }
  }
}

.alert-animation {
  animation: dangerAnim 0.4s ease-in-out;
  .input-title {
    h1 {
      font-weight: 300 !important;
      display: flex;
      gap: 5px;
      span {
        gap: 2px;
        display: flex;
        justify-content: center;
        align-items: baseline;
        font-size: 16px;
        img {
          filter: invert(1);
          margin: 0px 4px 2px 1px;
        }
      }
    }
    div {
      p {
        margin: 0px;
        max-width: 100%;
        width: inherit;
        font-weight: unset;
        font-size: 20px;
        line-height: 28px;
        color: var(--color);
        text-align: left;
        filter: opacity(0.7);
        padding-left: 5%;
      }
    }
  }
}

@keyframes dangerAnim {
  0% {
    transform: translateX(-5px);
  }
  25% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(-5px);
  }
  75% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}
.alert {
  opacity: 0 !important;
  transition: all 0.25s ease !important;
  transform: translateY(50px) !important;
  height: 0px !important;
  margin-top: 0px !important;
  overflow: hidden;
}
.alert.warning {
  height: fit-content !important;
  transform: translateY(0) !important;
  opacity: 1 !important;
  margin-top: 15px !important;
}
