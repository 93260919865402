.transactionSuccess-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  gap: 60px;
  .transactionid-header {
    display: flex;
    align-items: baseline;
    justify-content: center;
    font-size: 20px;
    span {
      font-size: 30px;
      margin-left: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      svg {
        cursor: pointer;
        font-size: 30px;
      }
    }
    .click-copy {
      font-size: 12px;
      margin: 0;
    }
  }
  .inputField {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    div {
      font-size: 20px;
    }
    input {
      color: var(--color);
    }
  }
  .login-text {
    font-size: 20px;
  }
  button {
    letter-spacing: 0.5px;
    font-size: 16px;
  }
}
.MuiInput-underline:before {
  border-color: var(--color) !important;
}
.MuiInput-underline:after {
  border-color: var(--color) !important;
}
