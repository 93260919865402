.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1250px;
  margin: -20px auto;
  height: 100%;
  transition: all 1s ease;
  animation: downScroll 1s ease;
  padding: 0 20px;
  scale: 0.9;
  @media (max-width: 800px) {
    justify-content: flex-start;
    .header {
      font-size: 20px !important;
      h2 {
        font-size: 26px !important;
        line-height: 1.2;
        margin: 15px 0;
      }
    }
  }
  .header {
    padding-top: 30px;
    line-height: 20px;
    word-spacing: 1px;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    h2 {
      letter-spacing: 1px;
      margin-top: 20px;
    }

    .content {
      display: grid;
      gap: 20px;
      margin: 30px 0 10px;
      & > span {
        color: #e22ecd;
        font-size: 18px;
        letter-spacing: 7px;
        font-weight: 500;
      }
      .card-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        gap: 25px;
        margin-top: 10px;
        @media (max-width: 1000px) {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          .service-container {
            width: 30%;
          }
        }
        @media (max-width: 590px) {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          gap: 0px;
          .service-container {
            width: 100%;
          }
        }
        &-com {
          display: grid;
          grid-template-columns: 200px 200px;
          gap: 20px;
          justify-content: center;
          @media (max-width: 590px) {
            grid-template-columns: 1fr;
          }
        }
      }

      .service-container {
        text-decoration: none;
        position: relative;
        border-radius: 25px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 10px 0 20px;
        align-items: flex-start;
        gap: 10px;
        height: fit-content;

        .index {
          position: absolute;
          background: -webkit-linear-gradient(#0b2f7a, #8c3bac);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          color: black;
          text-align: center;
          border-radius: 50%;
          width: 25px;
          height: 25px;
          top: -10px;
          left: 100px;
          font-weight: 600;
          font-size: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          &::after {
            content: "";
            background-color: #fff;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            position: absolute;
            inset: 0;
            z-index: -1;
          }
        }

        .topDiv {
          display: flex;
          gap: 10px;
          flex-direction: column;
          align-items: center;
          width: 80%;
          margin: 0 auto;
          img {
            height: 50px;
            flex-grow: 1;
          }
          span {
            font-size: 15px;
            color: var(--color);
            font-weight: 600;
          }
        }
      }
      .bottom-content {
        font-size: 13px;
        margin: 0 auto;
        height: fit-content;
        color: var(--color);
        opacity: 0.8;
      }
      @media (max-width: 500px) {
        grid-template-columns: 1fr;
        .service-container {
          &:last-child {
            grid-column: auto;
          }
        }
      }
    }
  }
  .end-content {
    text-align: center;
    justify-content: center;
    display: flex;
    width: 100%;
    max-width: 600px;
    letter-spacing: 0.5px;
    line-height: 21px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
  }
  .footer {
    text-align: center;
    z-index: 10;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    padding: 8px 16px;
    margin-top: 10px;
    margin-bottom: 40px;
    .time {
      margin: 0px;
      margin-top: 5px;
      max-width: 100%;
      width: inherit;
      font-weight: unset;
      font-size: 14px;
      line-height: 20px;
      color: var(--color);
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 3px;
    }
    div {
      button {
        border-radius: 29px;
        border: 1px solid white;
        background: var(--landing_btn_color);
        color: var(--landing_btn_txt_color);
        width: 152px;
        padding: 0;
        font-size: 16px;
        font-weight: 600;
        min-height: 30px;
      }
      span {
        font-size: 12px;
        margin-left: 5px;
      }
    }
  }
}

@keyframes downScroll {
  0% {
    transform: translateY(100vh);
    opacity: 0;
  }
  100% {
    transform: translateY(50vhvh);
    opacity: 1;
  }
}
