.checkbox-container {
  .checkbox-title {
    h1 {
      font-weight: 300 !important;
      display: flex;
      margin-left: -40px;
      gap: 5px;
      span {
        gap: 2px;
        display: flex;
        justify-content: center;
        align-items: baseline;
        font-size: 16px;
        img {
          filter: invert(1);
          margin: 0px 4px 2px 1px;
        }
      }
    }
  }
  .checkbox-button-container {
    .select-many {
      margin: 0px;
      max-width: 100%;
      width: inherit;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: var(--color);
    }
    display: grid;
    width: fit-content;
    gap: 15px;
    margin-top: 30px;
  }
}
