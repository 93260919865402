.payment {
  .Error-container {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    .error-content {
      border: 2px solid red;
      padding: 71px;
      background: white;
      color: red;
      border-radius: 17px;
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px;
        margin-bottom: 12px;
        img {
          height: 60px;
        }
      }
      .error-reason {
        font-size: 25px;
        margin: 0;
      }
    }
  }
  .payment-container {
    max-width: 700px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .payment-Header {
      font-size: 24px;
      margin-top: 30px;
    }
    button {
      width: 70%;
    }
  }
}

.MuiBackdrop-root{
  background-color: rgba(0, 0, 0, 0.7) !important;
}