.select-button,
.cost-button {
  border: 1px solid var(--color);
  text-transform: none !important;
  background-color: #ffffff22;
  .labelIndex {
    height: 24px;
    width: 24px;
    display: flex !important;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    border-width: 1px;
    border-style: solid;
    border-radius: 2px;
    border-color: rgba(255, 255, 255, 0.6);
    background-color: rgb(207, 207, 207);
    color: var(--color);
  }
  .btnLabel {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    flex: 1 1 0%;
    min-width: 75px;
    text-align: left;
    margin-left: 20px;
    color: var(--color);
    font-size: 20px;
    line-height: 28px;
    a {
      z-index: 2;
      text-decoration: none;
      color: var(--color);
    }
  }
  img {
    visibility: hidden;
  }
}
.cost-button{
  border: 1px solid var(--color) !important;
}
.radio-input {
  position: absolute;
  height: 100%;
  z-index: 1;
}
.radio-input + div > button {
  cursor: pointer;
  border: 1px solid var(--color);
  width: 100%;
  height: 100%;
}

.radio-input:checked + div > button {
  background-color: #ffffff22;
  border: 2px solid var(--color);
}
.check-input {
  // visibility: hidden;
  position: absolute;
  height: 40px;
  z-index: 1;
}
.check-input + div > button {
  cursor: pointer;
  border: 1px solid var(--color);
  width: 100%;
  height: 100%;
}

.check-input:checked + div > button {
  background-color: #ffffff22;
  border: 2px solid var(--color);
}

.radio-button {
  input {
    opacity: 0;
  }
  &:has(input:checked) {
    .MuiButtonBase-root {
      img {
        visibility: visible;
      }
    }
  }
}
.checkbox-button {
  input {
    cursor: pointer;
    opacity: 0;
    width: 100%;
    .MuiButtonBase-root {
      position: absolute;
    }
  }
  &:has(input:checked) {
    .MuiButtonBase-root {
      background-color: #ffffff22;
      border: 2px solid var(--color);
      img {
        visibility: visible;
      }
    }
  }
}
