.costFormat-container {
  margin-top: 20px;
  .export-quote {
    grid-column: 1/-1;
    display: grid;
    grid-template-columns: 280px 1fr;
    gap: 20px;
    align-items: end;
    margin-top: 10px;
    span {
      display: flex;
      justify-content: end;
      align-items: center;
    }
    .MuiInputBase-root {
      .MuiInputBase-input {
        color: #fff;
        &::placeholder {
          color: #fff;
        }
      }
    }
    span {
      font-size: 16px;
      text-align: right;
    }
  }
  .cost-container {
    h1 {
      font-weight: 500 !important;
      display: flex;
      gap: 5px;
      font-size: 20px !important;
      text-align: center;
      span {
        gap: 2px;
        display: flex;
        justify-content: center;
        align-items: baseline;
        font-size: 16px;
        img {
          filter: invert(1);
          margin: 0px 4px 2px 1px;
        }
      }
    }
    .costFormat-service {
      scale: 0.9;
      display: grid;
      .costComponent-container {
        display: grid;
        grid-template-columns: 280px 1fr;
        gap: 15px;
        position: relative;
        .costComponent-head {
          display: flex;
          justify-content: end;
          align-items: center;
          padding-top: 10px;
          gap: 7px;
          font-size: 22px;
          height: fit-content;
        }
        .costComponent-content {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          gap: 2px;
          position: relative;
          padding: 8px 0px;
          border-bottom: 1px solid #fff;
          font-size: 22px;

          .price-equalto {
            font-weight: 700;
          }
          .content-head {
            .head-count {
              font-size: 25px;
              font-weight: 800;
            }
            .head-service {
              font-size: 12px;
            }
          }
          .content-data {
            display: grid;
            .price {
              font-size: 22px;
              span {
                font-size: 20px;
                margin-left: 5px;
              }
            }
            .inner-foot {
              font-size: 12px;
            }
          }
        }
        .costComponent-foot {
          display: grid;
          justify-content: center;
          align-items: center;
          text-align: center;
          .foot-head,
          .foot-foot {
            font-size: 12px;
          }
          .price {
            font-size: 25px;
            font-weight: 800;
            span {
              font-size: 20px;
              margin-left: 5px;
            }
          }
        }
      }
      &:has(.costComponent-container:nth-child(3)) {
        .costComponent-container {
          .costComponent-content {
            font-size: 16px;
            .content-data {
              .price {
                font-size: 16px;
              }
            }
          }
          .costComponent-head {
            font-size: 16px;
          }
        }
      }
      &:has(.costComponent-container:nth-child(4)) {
        .costComponent-container {
          .costComponent-content {
            flex-direction: row;
            align-items: center;
            gap: 10px;
          }
        }
      }
      @media (max-width: 900px) {
        .costComponent-container {
          &:is(:nth-child(2), :nth-child(5), :nth-child(8)) {
            &::after {
              background-color: transparent;
            }
            &::before {
              background-color: transparent;
            }
          }
          &:nth-child(odd) {
            &::after {
              background-color: #fff;
            }
          }
        }
      }
      @media (max-width: 590px) {
        // grid-template-columns: 1fr;
        gap: 30px;
        scale: none;
        .costComponent-container {
          width: 100%;
          &:nth-child(odd) {
            &::after {
              background-color: transparent;
            }
          }
        }
      }
    }
  }
  .costFormat-options {
    .radio-container {
      .radio-title {
        .cost-suggestions {
          gap: 5px;
          h1 {
            font-size: 18px !important;
            margin: 0;
            position: relative;
            img {
              position: absolute;
              height: 40px;
              top: 50%;
              transform: translateY(-50%);
              left: 2px;
            }
            .cost-title {
              background-color: #f4f5f655;
              padding: 10px 10px 10px 45px;
              border-radius: 20px;
              font-size: 14px;
              width: 100%;
              justify-content: start;
              .minus-container {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 10px;
                gap: 3px;
                &:empty {
                  display: none;
                }
                img {
                  filter: none;
                  height: 45px;
                  margin-bottom: 6px;
                }
                .minusPercentage {
                  font-size: 25px;
                  font-weight: 600;
                  .symbol {
                    font-weight: 500;
                  }
                }
              }
            }
          }
        }
      }
    }
    margin-top: 15px;
    div {
      display: grid;
      .title {
        a {
          text-decoration: none;
          color: var(--color);
        }
      }
    }
    .radio-button-container {
      width: 100%;
      margin-top: 10px;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      .radio-button {
        button {
          .btnLabel {
            font-size: 14px;
            line-height: 18px;
          }
        }
      }
    }
  }
}
.form-container {
  max-width: 820px;
  &:has(.costFormat-container) {
    height: 100%;
    .each-card {
      max-height: 90vh;
      justify-content: flex-start;
      overflow: hidden auto;
      padding-right: 10px;
      padding-left: 0px;
      &::-webkit-scrollbar-track {
        background-color: #ffffff22;
      }
    }
  }
}
.totalCost {
  text-align: center;
  display: grid;
  grid-template-columns: 280px 1fr;
  column-gap: 20px;
  transform: scale(0.9);
  .cost {
    font-weight: 400 !important;
  }
  .sub-cost {
    text-align: left;
    font-size: 18px;
    margin-top: 0;
  }
  .symbol {
    margin-left: 3px;
    font-size: 20px;
  }
  div {
    font-size: 16px;
    font-weight: 600;
    &:has(span) {
      font-size: 25px;
      margin-top: 5px;
      span {
        font-size: 25px;
      }
    }
  }
  span {
    font-size: 12px;
  }
}
