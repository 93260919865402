.dropdown-container {
  width: 100%;
  .dropdown-title {
    h1 {
      font-weight: 300 !important;
      display: flex;
      margin-left: -40px;
      gap: 5px;
      span {
        gap: 2px;
        display: flex;
        justify-content: center;
        align-items: baseline;
        font-size: 16px;
        img {
          filter: invert(1);
          margin: 0px 4px 2px 1px;
        }
      }
    }
  }
  .MuiAutocomplete-root {
    .MuiFormControl-root {
      .MuiInputBase-root {
        input {
          font-size: 24px;
          padding: 10px 0;
          color: var(--color);
        }
        &::before {
          border-bottom: 1px solid #ffffff44;
        }
        &::after {
          border-bottom: 1px solid var(--color);
        }
        .MuiAutocomplete-endAdornment {
          button {
            color: var(--color);
          }
        }
      }
    }
  }
}

.MuiAutocomplete-listbox {
  background-color: transparent;
  .MuiAutocomplete-option {
    font-family: var(--font_family) !important;
    margin-bottom: 8px !important;
    background-color: #d0d0d0 !important;
    width: 100% !important;
    font-size: 18px !important;
    border-radius: 5px;
  }

  .Mui-focused {
    font-family: var(--font_family) !important;
    margin-bottom: 8px !important;
    background: #e8e8e8 !important;
    width: 100% !important;
    font-size: 18px !important;
  }
}
.MuiAutocomplete-popper {
  .MuiPaper-root {
    padding: 10px;
    .MuiAutocomplete-noOptions {
      padding: 10px 15px;
      background-color: #d0d0d0;
      color: #ffffff;
      border-radius: 5px;
    }
  }
}

.css-1x51dt5-MuiInputBase-input-MuiInput-input.Mui-disabled {
  -webkit-text-fill-color: var(--color) !important;
}
