.final-page-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .content {
    transition: all 1s ease;
    animation: downScroll 1s ease;
    font-size: 24px;
    text-align: center;
    max-width: 700px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    vertical-align: middle;
    line-height: 32px;
  }
}

@keyframes downScroll {
  0% {
    transform: translateY(100vh);
    opacity: 0;
  }
  100% {
    transform: translateY(70vh);
    opacity: 1;
  }
}
