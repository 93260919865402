.App {
  display: inline-block;
  position: relative;
  z-index: -20;
  opacity: 1;
  background-position: top center;
  background-size: cover !important;
  min-height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-attachment: fixed !important;
  color: var(--color);
  font-family: var(--font_family);
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
::-webkit-scrollbar {
  display: none;
  width: 6px;
  height: 10px;
}
::-webkit-scrollbar-track {
  background-color: #f4f5f6;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #aaa;
}
/* ::-webkit-scrollbar {
    width: 6px;
    height: 10px;
  }
  ::-webkit-scrollbar-track {
    background-color: #f4f5f6;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #aaa;
  } */
