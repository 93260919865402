h1 {
  font-size: 24px;
  line-height: 28px;
  color: var(--color);
}
.form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  max-width: 720px;
  margin: 0px auto;
  color: var(--color);
  form {
    padding: 0 15px;
  }
  .each-card {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
    padding-left: 40px;
    transition: all 0.3s ease;
    &.down {
      animation: downScroll 0.3s ease;
    }
    &.up {
      animation: upScroll 0.3s ease;
    }
    .service-button {
      display: flex;
      justify-content: center;
      gap: 15px;
    }
    .input-container {
      width: 100%;
      .input {
        margin-bottom: 20px;
        .MuiFormControl-root {
          width: 100%;
          .MuiFormLabel-root {
            color: var(--color);
            font-size: 18px;
            margin-bottom: 10px;
          }
          .MuiInputBase-root {
            input {
              font-size: 24px;
              padding: 10px 0;
              color: var(--color);
              &::placeholder {
                color: var(--color);
              }
            }
            &::before {
              border-bottom: 1px solid var(--color);
              opacity: 0.6;
            }
            &::after {
              border-bottom: 1px solid var(--color);
            }
          }
        }
        .MuiPaper-root {
          width: fit-content;
          padding: 5px 10px 5px 8px;
          margin-top: 15px;
          .MuiAlert-message {
            padding: 0;
            color: #b30000;
          }
          .MuiAlert-icon {
            padding: 0;
            margin-right: 5px;
          }
        }
      }
      .input-title {
        margin-bottom: 30px;
        margin-left: -40px;
        h1 {
          font-size: 24px;
        }
      }
    }
    .alert-animation {
      width: 100%;
      .input {
        margin-bottom: 20px;
        .MuiFormControl-root {
          width: 100%;
          .MuiFormLabel-root {
            color: var(--color);
            font-size: 18px;
            margin-bottom: 10px;
          }
          .MuiInputBase-root {
            input {
              font-size: 24px;
              padding: 10px 0;
              color: var(--color);
              &::placeholder {
                color: var(--color);
              }
            }
            &::before {
              border-bottom: 1px solid #ffffff44;
            }
            &::after {
              border-bottom: 1px solid var(--color);
            }
          }
        }
        .MuiPaper-root {
          width: fit-content;
          padding: 5px 10px 5px 8px;
          margin-top: 15px;
          .MuiAlert-message {
            padding: 0;
            color: #b30000;
          }
          .MuiAlert-icon {
            padding: 0;
            margin-right: 5px;
          }
        }
      }
      .input-title {
        margin-bottom: 30px;
        margin-left: -40px;
        h1 {
          font-size: 24px;
        }
      }
    }
    .Submit {
      display: flex;
      gap: 5px;
      font-weight: 100;
      div {
        a {
          color: var(--color);
        }
      }
      span {
        display: flex;
        font-size: 16px;
        gap: 2px;
        img {
          filter: invert(1);
        }
      }
    }
    .snackAlert {
      width: 30%;
      animation: alert 0.3s ease;
      margin-top: 20px;
      scale: 0.9;
    }
    .btnSpan {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      font-size: 12px;
      gap: 15px;
    }
  }
}
@keyframes downScroll {
  0% {
    transform: translateY(100vh);
    opacity: 0;
  }
  100% {
    transform: translateY(0vh);
    opacity: 1;
  }
}
@keyframes upScroll {
  0% {
    transform: translateY(-100vh);
    opacity: 0;
  }
  100% {
    transform: translateY(0vh);
    opacity: 1;
  }
}

@keyframes alert {
  0% {
    transform: translateY(50vh);
    opacity: 0;
  }
  100% {
    transform: translateY(0vh);
    opacity: 1;
  }
}
form {
  overflow: hidden;
}

.MuiCircularProgress-colorPrimary {
  color: var(--loaderColor) !important;
}

.MuiCircularProgress-root {
  width: 40px;
  height: 40px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 590px) {
  .title {
    font-size: 18px !important;
  }
}
