.radio-container {
  .radio-title {
    h1 {
      font-size: 24px;
      margin-left: -40px;
      margin-bottom: 30px;
      font-weight: 300 !important;
      display: flex;
      gap: 5px;
      span {
        display: flex;
        justify-content: center;
        align-items: baseline;
        font-size: 16px;
        img {
          filter: invert(1);
          margin: 0px 4px 2px 1px;
        }
      }
      .title {
        display: grid;
        font-size: 16px;
        justify-items: start;
        font-size: 24px;
        span {
          font-size: 20px;
          &:first-child {
            margin-top: 8px;
          }
          &:last-child {
            margin-bottom: 8px;
          }
        }
        &:has(span:nth-child(4)) {
          span {
            font-size: 18px;
          }
        }
        &:has(span:nth-child(6)) {
          span {
            font-size: 16px;
          }
        }
      }
      .cost-title {
        display: grid;
        font-size: 16px;
        justify-items: start;
        font-size: 20px;
        padding-left: 40px;
        span {
          font-size: 20px;
          &:first-child {
            margin-top: 8px;
          }
          &:last-child {
            margin-bottom: 8px;
          }
        }
        &:has(span:nth-child(4)) {
          span {
            font-size: 18px;
          }
        }
        &:has(span:nth-child(6)) {
          span {
            font-size: 16px;
          }
        }
      }
    }
  }
}
.radio-button-container {
  display: grid;
  width: fit-content;
  gap: 15px;
  .radio-button {
    position: relative;
    .radio-input {
      width: 100%;
      cursor: pointer;
    }
  }
}
