button {
  position: relative;
  line-height: inherit;
  font-weight: 700;
  cursor: pointer;
  transition-duration: 0.1s;
  transition-property: background-color, color, border-color, opacity,
    box-shadow;
  transition-timing-function: ease-out;
  outline: none;
  border: 1px solid transparent;
  box-shadow: rgb(0 0 0 / 10%) 0px 3px 12px 0px;
  padding: 6px 14px;
  min-height: 40px;
  background-color: var(--btn_color);
  color: var(--color);
  border-radius: 4px;
  margin-top: 30px;

  span {
    font-size: 20px;
    color: var(--btn_txt_clr);
    img {
      filter: invert(1);
      margin-left: 8px;
    }
  }
}
.css-6m3mwv-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root,
.css-b02hnd {
  position: relative !important;
  line-height: inherit !important;
  font-weight: 700 !important;
  cursor: pointer !important;
  transition-duration: 0.1s !important;
  transition-property: background-color, color, border-color, opacity,
    box-shadow !important;
  transition-timing-function: ease-out !important;
  outline: none !important;
  border: 1px solid transparent !important;
  box-shadow: rgb(0 0 0 / 10%) 0px 3px 12px 0px !important;
  padding: 6px 14px !important;
  min-height: 40px !important;
  min-width: 100px !important;
  background-color: var(--btn_color) !important;
  color: var(--color) !important;
  border-radius: 4px !important;
  margin-top: 30px !important;
  text-transform: none !important;
  font-size: 20px !important;
}
.loadingBtn {
  position: relative;
  padding: 20px;
  & > div {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    & > span {
      position: unset;
    }
  }
}
.service-cancel {
  background-color: #cdc9c9;
  span{
    color: #ffff;
  }
}
