.appointmentContainer {
  background-color: white;
  .content-container {
    min-height: calc(93vh - 163px);
    display: flex;
    justify-content: center;
    align-items: center;
    .elementor-widget-container {
      scale: 0.9;
      margin-bottom: -100px;
      margin-top: -65px;
      color: var(--color);
    }
  }
}
